<template>
  <div class="input-email-code">
    <div class="input-email-code__content">
      <p
        class="input-email-code__subtitle"
        v-html="$t('emailCode.code.subtitle', { email: email })"
      />
      <div
        v-if="errorMessage"
        class="input-email-code__error-message"
      >
        {{ $t(errorMessage) }}
      </div>
      <div class="input-email-code__boxes">
        <base-input
          v-for="(input, index) in inputs"
          :id="`inputBox${index}`"
          :key="input.id"
          :ref="`inputBox${index}`"
          :class="{ error: errorMessage }"
          :maxlength="1"
          :value="input"
          :autocomplete="false"
          placeholder="_"
          type="tel"
          @input="changeBoxOnInput(index)"
          @focus="clearErrorMessage"
          @paste="onCodePaste"
        />
      </div>
      <base-button
        :customizable="{ maxWidth: '430px' }"
        :is-type="['install', 'simple']"
        data-testid="verify-button"
        :has-loading="isLoading"
        :is-disabled="disabledButton"
        @onClick="validateCode"
      >
        {{ $t('emailCode.validate') }}
      </base-button>
      <p class="input-email-code__button-text">
        {{ $t('emailCode.spam') }}
      </p>
      <a
        class="simple-link"
        @click="$emit('goToHome')"
      >
        {{ $t('emailCode.go-back') }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
    components: {
        BaseInput: () =>
            import(/* webpackChunkName: "BaseInput"*/ '@RepoComponent/BaseInput/BaseInput'),
        BaseButton: () =>
            import(/* webpackChunkName: "BaseButton"*/ '@RepoComponent/BaseButton/BaseButton'),
    },
    props: {
        email: {
            type: String,
            default: '',
        },
        errorMessage: {
            type: String,
            default: null,
        },
        isLoading: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            inputs: ['', '', '', '', '', ''],
            disabledButton: true,
        };
    },
    methods: {
        changeBoxOnInput(index) {
            this.$forceUpdate();

            this.inputs[index] = this.$refs[`inputBox${index}`][0].$refs[`inputBox${index}`].value;
            const input = this.$refs[`inputBox${index}`][0].$refs[`inputBox${index}`];

            this.disabledButton = this.inputs.some(input => input === '');

            if (input.value && index < this.inputs.length - 1) {
                this.$refs[`inputBox${index + 1}`][0].$refs[`inputBox${index + 1}`].focus();
                return;
            }

            if (!input.value && index > 0) {
                this.$refs[`inputBox${index - 1}`][0].$refs[`inputBox${index - 1}`].focus();
                return;
            }
        },
        validateCode() {
            this.$emit('checkEmailCode', this.inputs);
        },
        clearErrorMessage() {
            this.$emit('clearErrorMessage');
        },
        onCodePaste(clipboard) {
            const text = clipboard.getData('text');
            const isDigitRegex = /^\d{6}$/;

            if (!isDigitRegex.test(text)) return;
            const array = [...text.trim()];
            array.forEach((digit, index) => {
                this.inputs[index] = digit;
                this.$forceUpdate();
            });
            this.validateCode();
        },
    },
};
</script>

<style src="./InputEmailCodeComponent.scss" lang="scss"></style>
